import { dew as _thematicBreakDew } from "./handlers/thematic-break";
import { dew as _listDew } from "./handlers/list";
import { dew as _wrapDew } from "./wrap";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = generateFootnotes;

  var thematicBreak = _thematicBreakDew();

  var list = _listDew();

  var wrap = _wrapDew();

  function generateFootnotes(h) {
    var footnoteById = h.footnoteById;
    var footnoteOrder = h.footnoteOrder;
    var length = footnoteOrder.length;
    var index = -1;
    var listItems = [];
    var def;
    var backReference;
    var content;
    var tail;

    while (++index < length) {
      def = footnoteById[footnoteOrder[index].toUpperCase()];

      if (!def) {
        continue;
      }

      content = def.children.concat();
      tail = content[content.length - 1];
      backReference = {
        type: "link",
        url: "#fnref-" + def.identifier,
        data: {
          hProperties: {
            className: ["footnote-backref"]
          }
        },
        children: [{
          type: "text",
          value: "\u21A9"
        }]
      };

      if (!tail || tail.type !== "paragraph") {
        tail = {
          type: "paragraph",
          children: []
        };
        content.push(tail);
      }

      tail.children.push(backReference);
      listItems.push({
        type: "listItem",
        data: {
          hProperties: {
            id: "fn-" + def.identifier
          }
        },
        children: content,
        position: def.position
      });
    }

    if (listItems.length === 0) {
      return null;
    }

    return h(null, "div", {
      className: ["footnotes"]
    }, wrap([thematicBreak(h), list(h, {
      type: "list",
      ordered: true,
      children: listItems
    })], true));
  }

  return exports;
}